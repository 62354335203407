html {
  font-size: 0.71em;
}

@media screen and (min-width: 1024px) {
  html {
    font-size: calc(0.71em + (16 - 11.36) * ((100vw - 1024px) / 416));
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 1em;
  }
}

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

[hidden] {
  display: none !important;
}

input:focus-visible {
  outline: none;
}
